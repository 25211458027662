<template>

    <el-dialog
        class="editDialog"
        :append-to-body="true"
        width="70vw"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"
    >

        <div slot="title" class="title">地图配置</div>

        <div class="content" v-loading="loading">
            <el-tabs v-if="showEditDialog">
                <el-tab-pane v-for="(item,key,index) in data" :label="key">
                    <div class="subject">
                        <div class="subjectMain layer">
                            <div class="label">注:勾选作为图层配置选中项,点选作为视图配置选中项</div>
                            <div class="con">
                                <el-scrollbar class="sidebar-wrapper">
                                    <el-tree :filter-node-method="filterNode" show-checkbox ref="layerTree" node-key="id" :default-checked-keys="item.defaultCheckedKeys" :data="layerTreeData" default-expand-all :expand-on-click-node="false">
                                        <div class="custom-tree-node"slot-scope="{ node, data }">
                                            <span class="name" :class="{'isLeaf':node.isLeaf,'active':treeNodeActive(data,item.viewJson)}" @click="catalogNameClick(node,data,key)">{{  data.catalogName  }}</span>
                                        </div>
                                    </el-tree>
                                </el-scrollbar>
                            </div>
                        </div>
                        <div class="subjectMain view">
                            <div class="label">
                                <el-radio-group v-model="activeShowJson" size="mini">
                                    <el-radio-button label="视图配置"></el-radio-button>
                                    <el-radio-button label="图层详情"></el-radio-button>
                                </el-radio-group>
                            </div>
                            <CodeEdit v-if="activeShowJson=='视图配置'" class="con" v-model="item.viewJson"></CodeEdit>
                            <CodeEdit v-else class="con" :disabled="true" v-model="activeLayerJson"></CodeEdit>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>
import {initTreeData} from "@/util/common";
import vkbeautify from "@/util/vkbeautify";
export default {
    components: {
        CodeEdit: () => import("@/components/CodeEdit.vue"),
    },
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default:function(){
                return {}
            }
        },
        refresh: {
            type: Function,
            default: () => {}
        },
    },
    data() {
        return {
            showEditDialog: false,
            loading: true,
            submitLoading: false,
            data:{
                '二维':{systemLayerType:2,defaultCheckedKeys:[],viewJson:'',},
                '三维':{systemLayerType:3,defaultCheckedKeys:[],viewJson:'',},
            },
            layerTreeData:[],
            activeShowJson:'视图配置',
            activeLayerJson:'',
        };
    },
    watch: {
        isShow: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                this.showEditDialog = newVal;
                if(newVal){
                    this.init(this.options);
                    this.getLayer()
                }
            }
        },
    },
    mounted(){},
    methods: {
        treeNodeActive(r,viewJson){
            var json = '';
            var layers = [];
            try{
                if(r.json){
                    json = JSON.parse(r.json).id;
                }

                var res = viewJson?JSON.parse(viewJson):{};
                if(res.layers){
                    res.layers.some(n=>{
                        layers.push(n.id);
                    })
                }
            }catch(err){
                console.log(err)
            };
            return layers.includes(json);
        },
        getLayer(){
            Promise.all([
                this.$api['admin/layerCatalog'].findList(),
                this.$api['admin/layer'].findList(),
            ]).then(r => {
                r[1].data.some(item=>{
                    item.parentId = item.layerCatalogId;
                    item.catalogName = item.layerName;
                    item.type = 'layer';
                    r[0].data.push(item);
                })
                this.layerTreeData = initTreeData(r[0].data);
                if(this.isShow){
                    this.$nextTick(()=> {
                        for(let i=0;i<this.data.length;i++) {
                            this.$refs.layerTree[i].filter();
                        }
                    });
                };
            }).catch(err => {
                console.log(err)
            });
        },
        catalogNameClick(node, data,key){
            if(node.isLeaf){
                this.activeLayerJson = data.json;
                var view = {};
                try{
                    view = this.data[key].viewJson?JSON.parse(this.data[key].viewJson):{};

                    if(view.layers){
                        if(view.layers.some(n=>{  return  n.id == JSON.parse(data.json).id  })){
                            for(let i=view.layers.length-1;i>-1;i--){
                                let n = view.layers[i]
                                if(n.id == JSON.parse(data.json).id){
                                    view.layers.splice(i,1);
                                }
                            }
                        }else {
                            view.layers.push(JSON.parse(data.json));
                        }
                    }else{
                        view.layers = [];
                        view.layers.push(JSON.parse(data.json));
                    };
                    this.data[key].viewJson = new vkbeautify().json(JSON.stringify(view));
                }catch(err){
                    console.log(err)
                };
                this.$set(this.data,key,this.data[key]);
            }
        },
        filterNode(value, data) {
            return data.type == 'layer';
        },
        init(options){

            this.loading = true;
            this.$api['admin/system'].findBySystemLayer(options.id).then(r=>{

                var arr2 = [];
                var arr3 = [];

                r.data.layerVo2s.some(r=>{
                    arr2.push(r.id)
                })
                r.data.layerVo3s.some(r=>{
                    arr3.push(r.id)
                })

                this.data['二维'].viewJson = r.data.viewJson2;
                this.data['三维'].viewJson = r.data.viewJson3;

                this.data['二维'].defaultCheckedKeys = arr2;
                this.data['三维'].defaultCheckedKeys = arr3;
                this.loading = false;

            }).catch(err => {
                this.loading = false;
                console.log(err)
            });

        },
        submit(){
            var arr = [];
            var keys = Object.keys(this.data);
            for(let i=0;i<keys.length;i++){
                var item = this.data[keys[i]];
                var layerIds = [];
                this.$refs.layerTree[i].getCheckedNodes().some(n=>{
                    if(n.type=='layer'){
                        layerIds.push(n.id);
                    }
                });
                arr.push(this.$api['admin/system'].saveSystemLayer({
                    layerIds,
                    systemId: this.options.id,
                    systemLayerType: item.systemLayerType,
                    viewJson: item.viewJson,
                }));
            };
            this.submitLoading = true;
            Promise.all(arr).then(res => {
                this.submitLoading = false;
                this.refresh();
                this.$message.success("操作成功！");
            }).catch(err => {
                console.log(err)
                this.submitLoading = false;
            });
        },
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
.editDialog {
    /deep/.el-dialog {
        .el-dialog__body {
            .content {
                padding:0 15px;
                height: calc(90vh - 164px);
                .el-tabs {
                    height:100%;
                    .el-tabs__content {
                        height:calc(100% - 54px);
                        .el-tab-pane {
                            height:100%;
                            overflow:auto;
                            .subject {
                                display:flex;
                                justify-content:space-between;
                                align-items:center;
                                height:100%;
                                .subjectMain {
                                    height:100%;
                                    &.layer {
                                        width:300px;
                                        .label {
                                            font-size:12px;
                                            color:$danger;
                                        }
                                        .con {
                                            height:calc(100% - 40px);
                                            border:#ddd solid 1px;
                                            padding:15px 5px;
                                            .sidebar-wrapper {
                                                height: 100%;
                                                .el-scrollbar__wrap {
                                                    overflow-x: hidden;
                                                }
                                            }
                                            .el-tree-node__content {
                                                cursor:auto;
                                                .name.isLeaf {
                                                    cursor:pointer;
                                                }
                                                .name.active {
                                                    color:$themeColor;
                                                }
                                            }
                                        }
                                    }
                                    &.view {
                                        width:calc(100% - 320px);
                                        .label {
                                            font-size:14px;
                                            fon-weight:bold;
                                        }
                                        .con {
                                            height: calc(100% - 40px);
                                        }
                                    }
                                    .label {
                                        line-height:40px;
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
